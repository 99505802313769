<template>
	<div class="navigation">
		<a href="#home">HOME</a>
		<a href="#about">ABOUT ME</a>
		<a href="#portfolio">PORTFOLIO</a>
		<a href="#contact-me">CONTACT ME</a>
	</div>
</template>

<script>
export default {
	name: 'Navigation',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navigation {
	font-size: 1.8em;
	position: absolute;
    z-index:10001;
    top: 10px;
    left: 0;
    right: 0;
    margin-top: 0;
    text-align: center;
}

a {
	padding: 10px 15px;
	background-color: #fff;
	color: #111;
	font-size: .7em;
	cursor: pointer;
	margin: 0 5px;
	text-decoration: none;
}

a:hover {
	background-color: #3e86ff;
	color: #fff;
}
</style>
