<!--
	{
		enable: true,
		title: "",
		summary: "",
		thumbnail: null,
		context: {
			about: [
				""
			],
			category: [
				""
			],
			company: {
				about: "",
				official_website: {
					link_website: "",
					link_text: ""
				}
			}
			roles: "",
			date: "",
			technologies: [
				""
			],
		},
		image_src: [
			"",
		],
		files_attached: [
			"",
		],
		link_source: "#",
		link_demo: "#",
		link_more: "#",
	},
-->

<template>
	<div class="projectDetails">
		<div class="title">
			{{ project.title }}
			<small class="date" v-if="project.context.date">[{{ project.context.date }}]</small>
		</div>
		
		<div class="section">
			<div class="section-title">About</div>
			<div class="section-content">
				<p v-for="(about, index) in project.context.about" :key="index">{{about}}</p>
			</div>
		</div>
		
		<div class="section" v-if="project.context.company">
			<div class="section-title">Company</div>
			<div class="section-content">
				<p>{{project.context.company.about}}</p>
			</div>
			<div class="section-content" v-if="project.context.company.official_website.link_website">
				<a :href="project.context.company.official_website.link_website">{{ project.context.company.official_website.link_text }}</a>
			</div>
		</div>
		
		<div class="section" v-if="project.context.roles">
			<div class="section-title">Roles</div>
			<div class="section-content">
				<p>{{project.context.roles}}</p>
			</div>
		</div>
		
		<div class="section" v-if="project.context.category">
			<div class="section-title">Category</div>
			<div class="section-content">
				<p v-for="(category, index) in project.context.category" :key="index">{{category}}</p>
			</div>
		</div>
		
		<div class="section" v-if="project.context.technologies">
			<div class="section-title">Technologies</div>
			<div class="section-content">
				<p v-for="(technology, index) in project.context.technologies" :key="index">{{technology}}</p>
			</div>
		</div>
		
		<div class="section" v-if="project.context.images">
			<div class="section-title">Images</div>
			<div class="section-content">
				<div v-for="(image, index) in project.context.images" :key="index">
					{{image}}
				</div>
			</div>
		</div>
		
		<div class="section" v-if="project.context.files_attached">
			<div class="section-title">Files attached</div>
			<div class="section-content">
				<div v-for="(file, index) in project.context.files_attached" :key="index">
					{{file}}
				</div>
			</div>
		</div>
		
		<a v-if="project.link_source" class="btn btn-secondary" :href="project.link_source">Source code</a>
		<a v-if="project.link_demo" class="btn btn-secondary" :href="project.link_demo">Demo</a>
	</div>
</template>

<script>
export default {
	name: 'projectCard',
	props: {
		project: Object,
	},
	methods: {
		selectProject(){
			this.$parent.selectProject(this.project)
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section-title {
	font-weight: 700;
	margin: 10px 0 0 0;
}

.section-content {
	margin: 10px 0 0 5px;
}

.section-content p {
	margin: 0;
}

.projectDetails {
	text-align: left;
}

.projectCard img {
	margin-top: -0.2px;
	margin-bottom: -3.8px;
}

.title {
    font-size: 1.4em;
}

.summary {
	margin: 10px;
}

a {
	margin: 1px 5px;
}

/*
a {
	background-color: #fff;
	color: #000;
	cursor: pointer;
	margin: 1px 15px;
}

a:hover {
	background-color: #000;
	color: #fff;
}
*/
</style>
