<template>
	<section class="portfolio">
		<a id="portfolio"></a>
		<div v-if="selected==null" class="portfolio-content">
			<div class="header">
				<h1>Projects</h1>
				<p>Projects I worked on.</p>
			</div>

			<div class="projects">
				<div class="row"> 
					<div class="column">
						<template v-for="(project, index) in projects">
							<div class="projects--items" v-if="index%4 == 0" :key="index">
								<ProjectCard :project="project"></ProjectCard>
							</div>
						</template>
					</div>
				</div>
				
				<div class="row"> 
					<div class="column">
						<template v-for="(project, index) in projects">
							<div class="projects--items" v-if="index%4 == 1" :key="index">
								<ProjectCard :project="project"></ProjectCard>
							</div>
						</template>
					</div>
				</div>
				
				<div class="row"> 
					<div class="column">
						<template v-for="(project, index) in projects">
							<div class="projects--items" v-if="index%4 == 2" :key="index">
								<ProjectCard :project="project"></ProjectCard>
							</div>
						</template>
					</div>
				</div>
				
				<div class="row"> 
					<div class="column">
						<template v-for="(project, index) in projects">
							<div class="projects--items" v-if="index%4 == 3" :key="index">
								<ProjectCard :project="project"></ProjectCard>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="project-content">
			<button class="btn btn-secondary btn-back" @click="clearSelection">&#60; Return to the list of projects</button>
			<ProjectDetails :project="selected"></ProjectDetails>
			<button class="btn btn-secondary btn-back" @click="clearSelection">&#60; Return to the list of projects</button>
		</div>
	</section>
</template>

<script>
import ProjectCard from './ProjectCard.vue'
import ProjectDetails from './ProjectDetails.vue'
import { MY_PROJECTS } from '../assets/projects.js'

export default {
	name: 'PortFolio',
	data () {
		return {
			projects: MY_PROJECTS,
			selected: null
		}
	},
	methods: {
		jumpTo(h){
			window.scrollTo(0, document.getElementById(h).offsetTop)
		},
		selectProject(project){
			this.selected = project
			this.jumpTo("portfolio")
		},
		clearSelection(){
			this.selected = null
			this.jumpTo("portfolio")
		}
	},
	components: {
		ProjectCard,
		ProjectDetails
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.portfolio {
	min-height: 50vh;
}

.portfolio-content, .project-content{
	width: 70%;
	margin: 15px auto;
	border-bottom: 1px solid #000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.project-content {
	margin: 0 auto;
}

.btn-back {
	margin: 15px;
}

.portfolio {
	text-align: center;
	overflow: hidden;
}

.projects > * {
    margin-top: 10px;
}

.projects {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -5px;
	justify-content: center;
}

.projects--items {
	margin: 25px 15px;
}

.header p {
	margin: 0 auto;
}
</style>
