<template>
	<div class="projectCard" :class="noSpace(project.context.category)">
		<div class="title">{{ project.title }}</div>
		<img v-if="project.thumbnail" class="thumbnail" :src="getImgUrl(project.thumbnail)"  />
		<div class="summary">{{ project.summary }}</div>
		<!--
		<a v-if="project.link_source" class="btn btn-success" :href="project.link_source">Source code</a>
		<a v-if="project.link_demo" class="btn btn-danger" :href="project.link_demo">Demo</a>
		<button class="btn btn-info" @click="selectProject">More</button>
		-->
	</div>
</template>

<script>
export default {
	name: 'projectCard',
	props: {
		project: Object,
		index: Number,
	},
	methods: {
		noSpace(categories){
			if(!categories) return null
			var category_classes = []
			categories.forEach((category) => {
				category_classes.push(category.split(' ').join('-').toLowerCase())
			})
			return category_classes 
		},
		selectProject(){
			this.$parent.selectProject(this.project)
		},
		getImgUrl(pic) {
			return require('../'+pic)
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.projectCard {
	position: relative;
	width: 300px;
	background-color: #d2d2d2;
    box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.5);
	padding-bottom: 5px;
	border-radius: 0 15px 0 30px;
}

.thumbnail {
	width: 100%;
	max-height: 100px;
}

.projectCard img {
	margin-top: -0.2px;
	margin-bottom: -3.8px;
}

.title {
    font-size: 1.4em;
	padding: 5px 0;
}

.summary {
	margin: 10px;
}

a, button.btn {
	margin: 1px 5px;
}

.open-source {
	border: 2px solid #e62821;
}

.cern {
	border: 2px solid #3e90ce;
}

.engineering-school {
	border: 2px solid #42b481;
}

.bts {
	border: 2px solid #e44d27;
}

.entrepreneurship {
	border: 2px solid #eedb4d;
}

.preparatory-school {
	border: 2px solid #563e7e;
}

</style>
