<template>
	<section class="technologies">
		<div class="technologies-content">
			<div class="experience-with">
				<div class="header">
					<h1>I have experience with</h1>
				</div>
				<ul class="technologies-list">
					<li class="technologies-item tooltip" v-for="(technology, index) in technologies" :key="index">
						<img :src="getImgUrl(technology.image_src)"  />
						<span class="tooltiptext">
							<strong>{{ technology.name }}</strong>
							<span v-if="technology.description" class="technologies-item-description">{{ technology.description }}</span>
						</span>
					</li>
				</ul>
			</div>
		</div>
	</section>
</template>

<script>
import { MY_TECHNOLOGIES } from '../assets/technologies.js'

export default {
	name: 'Technologies',
	data () {
		return {
			technologies: MY_TECHNOLOGIES
		}
	},
	methods: {
		getImgUrl(pic) {
			return require('../'+pic)
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.technologies {
	min-height: 40vh;
}

.technologies-content {
	width: 70%;
	justify-content: center;
	margin: 0 auto 15px auto;
	display: flex;
	border-bottom: 1px solid #000;
}

.experience-with {
	display: flex;
	justify-content: center;
	width: 100%;
	flex-direction: column;
	padding-bottom: 35px;
}

.technologies-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	width: 100%;
}

.technologies-list li {
	margin: 0 40px;
}

.technologies-item {
	list-style : none;
}

.technologies-item img {
    max-width: 150px;
    max-height: 150px;
    margin-top: 25px;
}

.technologies-item-description::before {
	content: " - ";
}
</style>
