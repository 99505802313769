export const MY_TECHNOLOGIES = [
	{
		name: "Bootstrap",
		image_src: "assets/images/logos/BOOTSTRAP.png",
	},
	{
		name: "CSS3",
		image_src: "assets/images/logos/CSS3.png",
	},
	{
		name: "Docker",
		image_src: "assets/images/logos/DOCKER.png",
	},
	{
		name: "HTML5",
		image_src: "assets/images/logos/HTML5.png",
	},
	{
		name: "JavaScript",
		image_src: "assets/images/logos/JAVASCRIPT.png",
	},
	{
		name: "Linux",
		image_src: "assets/images/logos/LINUX.png",
	},
	{
		name: "Nginx",
		image_src: "assets/images/logos/NGINX.png",
	},
	{
		name: "PHP7",
		image_src: "assets/images/logos/PHP7.png",
	},
	{
		name: "PL/SQL",
		image_src: "assets/images/logos/PL_SQL.png",
	},
	{
		name: "vue.js",
		image_src: "assets/images/logos/VUE_JS.png",
	},
]

/*

	{
		name: "After Effects",
		image_src: "assets/images/logos/AFTER_EFFECTS.png",
	},
	{
		name: "Behance",
		image_src: "assets/images/logos/BEHANCE.png",
	},
	{
		name: "Blogspot",
		image_src: "assets/images/logos/BLOGSPOT.png",
	},
	{
		name: "Bootstrap",
		image_src: "assets/images/logos/BOOTSTRAP.png",
	},
	{
		name: "CSS3",
		image_src: "assets/images/logos/CSS3.png",
	},
	{
		name: "Deviantart",
		image_src: "assets/images/logos/DEVIANTART.png",
	},
	{
		name: "Discord",
		image_src: "assets/images/logos/DISCORD.png",
	},
	{
		name: "Docker",
		image_src: "assets/images/logos/DOCKER.png",
	},
	{
		name: "Dribble",
		image_src: "assets/images/logos/DRIBBLE.png",
	},
	{
		name: "Facebook",
		image_src: "assets/images/logos/FACEBOOK.png",
	},
	{
		name: "Github",
		image_src: "assets/images/logos/GITHUB.png",
	},
	{
		name: "Google +",
		image_src: "assets/images/logos/GOOGLE+.png",
	},
	{
		name: "HTML5",
		image_src: "assets/images/logos/HTML5.png",
	},
	{
		name: "Instagram",
		image_src: "assets/images/logos/INSTAGRAM.png",
	},
	{
		name: "Instant Gaming",
		image_src: "assets/images/logos/INSTANT_GAMING.png",
	},
	{
		name: "JavaScript",
		image_src: "assets/images/logos/JAVASCRIPT.png",
	},
	{
		name: "LinkedIn",
		image_src: "assets/images/logos/LINKEDIN.png",
	},
	{
		name: "Linux",
		image_src: "assets/images/logos/LINUX.png",
	},
	{
		name: "Mail",
		image_src: "assets/images/logos/MAIL.png",
	},
	{
		name: "Nginx",
		image_src: "assets/images/logos/NGINX.png",
	},
	{
		name: "Patreon",
		image_src: "assets/images/logos/PATREON.png",
	},
	{
		name: "PHP7",
		image_src: "assets/images/logos/PHP7.png",
	},
	{
		name: "Pinterest",
		image_src: "assets/images/logos/PINTEREST.png",
	},
	{
		name: "PL/SQL",
		image_src: "assets/images/logos/PL_SQL.png",
	},
	{
		name: "Skype",
		image_src: "assets/images/logos/SKYPE.png",
	},
	{
		name: "SoundCloud",
		image_src: "assets/images/logos/SOUNDCLOUD.png",
	},
	{
		name: "Tinder",
		image_src: "assets/images/logos/TINDER.png",
	},
	{
		name: "Tipeee",
		image_src: "assets/images/logos/TIPEEE.png",
	},
	{
		name: "Tumblr",
		image_src: "assets/images/logos/TUMBLR.png",
	},
	{
		name: "Twitch",
		image_src: "assets/images/logos/TWITCH.png",
	},
	{
		name: "Twitter",
		image_src: "assets/images/logos/TWITTER.png",
	},
	{
		name: "TypeScript",
		image_src: "assets/images/logos/TYPESCRIPT.png",
	},
	{
		name: "Utip",
		image_src: "assets/images/logos/UTIP.png",
	},
	{
		name: "Viameo",
		image_src: "assets/images/logos/VIAMEO.png",
	},
	{
		name: "Viber",
		image_src: "assets/images/logos/VIBER.png",
	},
	{
		name: "vue.js",
		image_src: "assets/images/logos/VUE_JS.png",
	},
	{
		name: "WhatsApp",
		image_src: "assets/images/logos/WHATSAPP.png",
	},
	{
		name: "Yahoo",
		image_src: "assets/images/logos/YAHOO.png",
	},
	{
		name: "Youtube",
		image_src: "assets/images/logos/YOUTUBE.png",
	},

*/