<template>
	<section class="about">
		<div class="about-content">
			<a id="about"></a>
			
			<div class="header">
				<h1>About</h1>
				<p>A technology-driven full-stack software engineer with more than 6 years of experience in the IT sector. Passionate about computer science and innovation, I enjoy combining them to provide highly performant solutions. I also pay special attention to the client’s needs and to the quality of what I produce.</p>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'About',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.about {
	min-height: 35vh;
	background: linear-gradient(to bottom, #0a2a43 0%,#e8e8e8 5%);
}

.about-content {
	width: 70%;
	justify-content: center;
	margin: 35px auto;
	display: flex;
	border-bottom: 1px solid #000;
}
</style>
