<template>
	<section class="home">
		<img src="../assets/images/home/bg.jpg" id="bg" :style="{ top: bg_top + 'px' }">
		<img src="../assets/images/home/moon.png" id="moon" :style="{ left: moon_left + 'px' }">
		<img src="../assets/images/home/mountain.png" id="mountain" :style="{ top: mountain_top + 'px' }">
		<img src="../assets/images/home/road.png" id="road" :style="{ top: road_top + 'px' }">
		<div id="text" :style="{ top: text_top + 'px' }">
			<div class="main">Alexandre Giraud</div>
			<div class="secondary">Toujours plus loin !</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'Home',
	data() {
		return {
			bg_top: 0,
			moon_left: 0,
			mountain_top: 0,
			road_top: 0,
			text_top: 0,
		}
	},
	created () {
		window.addEventListener('scroll', this.handleScroll);
	},
	destroyed () {
		window.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		handleScroll () {
			var value = window.scrollY
			
			this.bg_top = value * 0.5;
			this.moon_left = value * 0.5;
			this.mountain_top = -value * 0.15;
			this.road_top = value * 0.15;
			this.text_top = value * 1;
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
}

.main {
    font-size: .5em;
}

.secondary {
    font-size: .2em;
}

.home {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.home:before {
	content: '';
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 100px;
	background: linear-gradient(to top, #0a2a43, transparent);
	z-index: 10000;
}

.home:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0a2a43;
	z-index: 10000;
	mix-blend-mode: color;
}

.home img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	pointer-events: none;
}

#text {
	position: relative;
	color: #fff;
	font-size: 10em;
	z-index: 1;
}

#road {
	z-index: 2;
}
</style>
