export const MY_SOCIAL_NETWORKS = [
	{
		name: "Github",
		image_src: "assets/logos/GITHUB.png",
		link: "#",
	},
	{
		name: "LinkedIn",
		image_src: "assets/logos/LINKEDIN.png",
		link: "#",
	},
	{
		name: "Mail",
		image_src: "assets/logos/MAIL.png",
		link: "#",
	},
]