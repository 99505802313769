export const MY_PRACTICES = [

]

	/*{
		title: "Test",
		description: "lorem",
		image_src: "https://picsum.photos/id/2/300/150",
		link_source: "#",
		link_demo: "#",
		link_more: "#",
	},
	{
		title: "Test",
		description: "lorem",
		image_src: "https://picsum.photos/id/3/300/210",
		link_source: "#",
		link_demo: "#",
	},
	{
		title: "Test",
		description: "lorem",
		image_src: "https://picsum.photos/id/4/300/352",
		link_source: "#",
		link_demo: "#",
	},
	{
		title: "Test",
		description: "lorem",
		image_src: "https://picsum.photos/id/5/300/414",
		link_source: "#",
		link_demo: "#",
	},
	{
		title: "Test",
		description: "lorem",
		image_src: "https://picsum.photos/id/6/300/635",
		link_source: "#",
		link_demo: "#",
	},
	{
		title: "Test",
		description: "lorem",
		image_src: "https://picsum.photos/id/7/300/832",
		link_source: "#",
		link_demo: "#",
	},
	{
		title: "Test",
		description: "lorem",
		image_src: "https://picsum.photos/id/8/300/423",
		link_source: "#",
		link_demo: "#",
	},
	{
		title: "Test",
		description: "lorem",
		image_src: "https://picsum.photos/id/9/300/920",
		link_source: "#",
		link_demo: "#",
	},
	{
		title: "Test",
		description: "lorem",
		image_src: "https://picsum.photos/id/10/300/524",
		link_source: "#",
		link_demo: "#",
	},
	{
		title: "Test",
		description: "lorem",
		image_src: "https://picsum.photos/id/11/300/354",
		link_source: "#",
		link_demo: "#",
	},*/