/*

	*** KEEP IN MIND ***
	
	Situation: 
		Describe the situation that you were in or the task that you needed to accomplish. You must describe a specific event or situation, not a generalized description of what you have done in the past. Be sure to give enough detail for the interviewer to understand. This situation can be from a previous job, from a volunteer experience, or any relevant event.
	Task: 
		What goal were you working toward?
	Action:
		Describe the actions you took to address the situation with an appropriate amount of detail and keep the focus on YOU. What specific steps did you take and what was your particular contribution? Be careful that you don’t describe what the team or group did when talking about a project, but what you actually did. Use the word “I,” not “we” when describing actions.
	Result:
		Describe the outcome of your actions and don’t be shy about taking credit for your behavior. What happened? How did the event end? What did you accomplish? What did you learn? Make sure your answer contains multiple positive results. 

	*** Template ***

	{
		enable: true,
		title: "",
		summary: "",
		thumbnail: null,
		context: {
			about: [
				""
			],
			category: [
				""
			],
			company: {
				about: "",
				official_website: {
					link_website: "",
					link_text: ""
				}
			}
			roles: "",
			date: "",
			technologies: [
				""
			],
		},
		images: [
			{
				title: "",
				path: ""
			}
		],
		files_attached: [
			"",
		],
		link_source: "#",
		link_demo: "#",
	},
	
*/

export const MY_PROJECTS = [
	
	/* ************************************************* */
	/* Open Source										 */
	/* ************************************************* */
	/*
		Charon
			Spring boot/Spring security
			JS
			Cassandra
			Oauth2
	*/
	{
		enable: true,
		title: "Charon",
		summary: "Fully customizable OAuth server based on Spring security Oauth2",
		thumbnail: "assets/images/projects/thumbnails/58-300x100.jpg",
		context: {
			about: [
				"Fully customizable OAuth server based on Spring security Oauth2, I personally developed."
			],
			category: [
				"Open Source"
			],
			roles: "All roles",
			date: "2019",
			technologies: [
				"Java",
				"Spring",
				"Spring boot",
				"Spring security",
				"Cassandra",
				"Oauth2",
			],
		},
		link_source: "https://github.com/alexandregiraud64/charon",
	},
	
	/*
		Manga-translator
			Python/OpenCV
			Traitement images
			Algorithme text detection/In Painting
	*/
	{
		enable: true,
		title: "Manga translator",
		summary: "Open source library to automatically translate and redraw manga pages",
		thumbnail: "assets/images/projects/thumbnails/-1-300x100.png",
		context: {
			about: [
				"Manga-translator is an open source library, I am currently working on.",
				"This library is not available yet.",
				"Once ready, it will allow to automatically translate and redraw manga pages using OpenCV.",
			],
			category: [
				"Open Source"
			],
			roles: "",
			date: "",
			technologies: [
				"Python",
				"OpenCV"
			],
		},
		//link_source: "#",
	},
	
	/* ************************************************* */
	/* Entrepreneurship									 */
	/* ************************************************* */
	/*
		Communication
			Définir besoin avec professionels santé
			Pitcher devant investisseurs
			Apprendre bases:
				Finances
				Étude de marché
				Story telling
				Legal aspect
				...
			Découverte coach relation concept échanger avec entrepreneurs
			Base négociation investisseurs
			Sharing knowledge
	*/
	{
		enable: true,
		title: "Entrepreneurship - communication aspect",
		summary: "Communication aspects of the entrepreneurship project",
		thumbnail: "assets/images/projects/thumbnails/3-300x100.jpg",
		context: {
			about: [
				"In order to become a entrepreneur, I learned a lot of skills communication related.",
				"The main objective was to be able to pitch in front of investors.",
				"In order to achieve this objective, I took part in a training organised by Innosuisse",
				"In the context of this training, I also got coaching sessions."
			],
			category: [
				"Entrepreneurship"
			],
			company: {
				about: "Innosuisse supports science-based innovations of companies, researchers, start-ups and other innovative organisations in Switzerland.",
				official_website: {
					link_website: "https://www.innosuisse.ch/inno/de/home.html",
					link_text: "Innosuisse"
				}
			},
			roles: "Learner and entrepreneur",
			date: "2019",
		},
	},
	
	/*
		Technique
			Cloud architecture / Linux
			LDAP / Open LDAP
			NoSQL / Cassandra
			Puppet/Docker/Docker-compose
			Scripts shell
			Let's encrypt
			Nginx/Haproxy
			SMTP
			Vue.JS
			Spring - Spring boot/Spring data/Spring MVC/Spring security
			OAuth server
			Git/GitLab/GitHub
			(FHIR-Keycloak)
			Graphana/Prometheus
	*/
	{
		enable: true,
		title: "Entrepreneurship - technical aspect",
		summary: "Technical aspects of the entrepreneurship project",
		thumbnail: "assets/images/projects/thumbnails/122-300x100.jpg",
		context: {
			about: [
				"I engineered an exchange and storage platform for Health professionals as a cloud-based solution. Project handled from end-to-end, performing multiple roles simultaneously as infrastructure architect, system administrator or full-stack developer.",
				"I developed an application architecture using Spring Boot and Spring Data Cassandra, designing a Cassandra database and implementing the corresponding data repositories",
				"Provided data access through a RESTful API, secured by an OAuth server (Charon – see Professional Development section) based on Spring framework",
				"Designed and implemented the website’s frontend using Vue.js and CSS3 with Bootstrap",
				"Ensured the infrastructure and network security by using TLS 1.2 certificates, NGINX security configuration and monitoring via a Prometheus instance sending mail alerts through a private mail server (SMTP/IMAP)"
			],
			category: [
				"Entrepreneurship"
			],
			company: {
				about: "",
				official_website: {
					link_website: "",
					link_text: ""
				}
			},
			roles: "",
			date: "",
			technologies: [
				"Cloud",
				"OVH",
				"Linux",
				"LDAP",
				"OpenLDAP",
				"NoSQL",
				"Cassandra",
				"Docker",
				"Puppet",
				"Shell",
				"Let's encrypt",
				"Nginx",
				"Haproxy",
				"SMTP",
				"Vue.JS",
				"Spring",
				"Spring boot",
				"Spring data",
				"Spring MVC",
				"Spring security",
				"OAuth server",
				"Git",
				"GitLab",
				"GitHub",
				"FHIR",
				"Keycloak",
				"Graphana",
				"Prometheus",
			],
		},
		images: [
			{
				title: "",
				path: "assets/files/projects/entrepreneurship/architecture.png",
			}
		],
	},
	
	/* ************************************************* */
	/* CERN												 */
	/* ************************************************* */
	/*
		FESA/Radmon
			Support/Maintain
			Convert from FESA 2 to FESA 3
			+400 devices +20 FEC
			Développer monitoring tools
			Contact direct avec utilisateurs
	*/
	{
		enable: true,
		title: "RadMon",
		summary: "Debugging of real-time data acquisition modules",
		thumbnail: "assets/images/projects/thumbnails/26-300x100.jpg",
		context: {
			about: [
				"My first mission when I arrived at CERN was to debug a real-time data acquisition software. The software was developed in 2012 using FESA (Front End Software Architecture) and the hardware part was called RadMon (Radiation Monitoring).",
				"The problem was that randomly a bunch of data was completely corrupted. Two people had already spend time debugging the system unsuccessfully.",
				"Once the bug found and the system restored, I have been asked to upgrade the system from FESA version 2 to FESA version 3 and then maintain it during 4 years. The system is divided on 21 FECs (Front End Computer) and approximately  400 Radmons all around the LHC (Large Hadron Collider), the longest particles accelerator of the world.",
				"My supervisor appreciated my efficiency. Moreover, the technicians I was working with enjoyed the tools I developed to help them to monitor the system with a single web page.",
			],
			category: [
				"CERN"
			],
			roles: "Developer",
			date: "2014-2018",
			technologies: [
				"C++",
				"FESA",
				"Timber",
				"CALS",
			],
		},
		images: [
			{
				title: "",
				path: "assets/files/projects/cern/radmon/radmon.png",
			}
		],
	},
	
	/*
		n_TOF/EACS
			Synchronisation système pour une acquisition 24/24 7/7
			Comprendre les besoins des clients + utilisateurs
			C++/Valgrind/GDB/DIM
			On call
	*/
	{
		enable: true,
		title: "EACS (n_TOF)",
		summary: "Synchronisation module for a data acquisition system running 24/7",
		thumbnail: "assets/images/projects/thumbnails/5-300x100.jpg",
		context: {
			about: [
				"The second project I worked on was the n_TOF project. n_TOF, for Neutrons_Time of Flight, is one of the CERN experiments. Within a team of 6 people, we had to build a real time data acquisition system able to acquire up to 2GB per second.",
				"As the system was divided into 2 experimental areas and 10 servers, it was decided to add a synchronisation module to the system. That's the module I was in charge of developing. Then I also deployed and maintained this module.",
				"The system has been really appreciated by the client and reached 98.8% of up time."
			],
			category: [
				"CERN"
			],
			company: {
				about: "n_TOF is a pulsed neutron source coupled to a 200 m flight path designed to study neutron-nucleus interactions for neutron kinetic energies ranging from a few meV to several GeV. The neutron kinetic energy is determined by time-of-flight, hence the name n_TOF.",
				official_website: {
					link_website: "https://ntof-exp.web.cern.ch/",
					link_text: "n_TOF"
				}
			},
			roles: "Developer",
			date: "2014-2018",
			technologies: [
				"C++",
				"Valgrind",
				"GDB",
				"DIM"
			],
		},
		images: [
			{
				title: "",
				path: "assets/files/projects/cern/ntof/ntof.png"
			}
		],
	},
	
	/*
		n_TOF/Monitoring
			Propositions spontanées
			Bootstrap/jQuery/WebSocket
			Conflict first
			Very useful -> Ajouter à la Control Room
	*/
	{
		enable: true,
		title: "Monitoring system (n_TOF)",
		summary: "Monitoring system used for the n_TOF experiment",
		thumbnail: "assets/images/projects/thumbnails/9-300x100.jpg",
		context: {
			about: [
				"The monitoring system used for the n_TOF experiment was done using a web server proxy used to retrieved data from different sources like, DIP, DIM or RDA (FESA). The web server also used a web socket system to push the data to the javascript interface allowing a real time monitoring system with precise information. I developed the pages using jQuery. The system has been really appreciated by the developers for debugging purpose and are now daily used by the operators as the are displayed 24/7 in the Control room on 2 big screens.",
				"I am at the initiative of this project and I personally developed the first version."
			],
			category: [
				"CERN"
			],
			company: {
				about: "n_TOF is a pulsed neutron source coupled to a 200 m flight path designed to study neutron-nucleus interactions for neutron kinetic energies ranging from a few meV to several GeV. The neutron kinetic energy is determined by time-of-flight, hence the name n_TOF.",
				official_website: {
					link_website: "https://ntof-exp.web.cern.ch/",
					link_text: "n_TOF"
				}
			},
			roles: "Initiator and developer",
			date: "2015-2018",
			technologies: [
				"JavaScript",
				"BootStrap",
				"jQuery",
				"WebSocket"
			],
		},
		images: [
			{
				title: "",
				path: "assets/files/projects/cern/ntof/ntof.png"
			},
			{
				title: "",
				path: "assets/files/projects/cern/ntof/screenshot.png"
			}
		],
	},
	
	/*
		n_TOF/ntoflib
			Library réalisée en collaboration avec l'équipe clients
			Rédaction de la documentation (+ maintenance)
			Nécessaire pour faire cohabiter les legacy codes avec les nouveaux users
	*/
	{
		enable: true,
		title: "ntoflib (n_TOF)",
		summary: "Library used to read specific binary files",
		thumbnail: "assets/images/projects/thumbnails/2-300x100.jpg",
		context: {
			about: [
				"In order to keep the compatibility with the previous versions of the files created by the application, I designed, developed and maintaines a library. It has been developed using C++ as required by the clients.",
				"The most challenging part was the lack of documentation to understand how the previous files was organised.",
				"In order to ensure that the datawas correct, I written a full documentation on how the files was formed and had it validated by every member of the client's team involved in the analysis of the files."
			],
			category: [
				"CERN"
			],
			company: {
				about: "n_TOF is a pulsed neutron source coupled to a 200 m flight path designed to study neutron-nucleus interactions for neutron kinetic energies ranging from a few meV to several GeV. The neutron kinetic energy is determined by time-of-flight, hence the name n_TOF.",
				official_website: {
					link_website: "https://ntof-exp.web.cern.ch/",
					link_text: "n_TOF"
				}
			},
			roles: "Developer",
			date: "2015-2018",
			technologies: [
				"C++"
			],
		},
		images: [
			{
				title: "",
				path: "assets/files/projects/cern/ntof/ntof.png"
			},
		],
	},
	
	/*
		n_TOF/Data viewer
			Qt5 (C++)
			Full development
			Autonomie
			Documentation/Maintenance
			Algorithme de décimation verticale
			Déploiement
			Support
			Training users
	*/
	{
		enable: true,
		title: "File viewer (n_TOF)",
		summary: "Software used to display data as charts",
		thumbnail: "assets/images/projects/thumbnails/41-300x100.jpg",
		context: {
			about: [
				"While I was working at CERN, I participated to develop the data acquisition system for the experiment n_TOF. Once the data was correctly recorded by the system, the next step was to be able to analyze them.",
				"A legacy software, developed in Java, was already available, but couldn't be maintained. Moreover specific C++ libraries were required in order to perform advanced analysis. So I designed and developed a new software called 'File Viewer'.",
				"In order to optimise the data visualisation, the library selected was implementing a vertical decimation algorithm.",
				"I also conducted workshop with the client team in order to ensure that the software was answering their needs.",
				"This system was able to display more than 50 million of data within less than 10 milliseconds. The users were really happy with the new software and since its release it has been used daily by the operators in the control room."
			],
			category: [
				"CERN"
			],
			company: {
				about: "n_TOF is a pulsed neutron source coupled to a 200 m flight path designed to study neutron-nucleus interactions for neutron kinetic energies ranging from a few meV to several GeV. The neutron kinetic energy is determined by time-of-flight, hence the name n_TOF.",
				official_website: {
					link_website: "https://ntof-exp.web.cern.ch/",
					link_text: "n_TOF"
				}
			},
			roles: "All roles",
			date: "2015-2018",
			technologies: [
				"C++",
				"Qt5",
			],
		},
		images: [
			{
				title: "",
				path: "assets/files/projects/cern/ntof/ntof.png"
			},
			{
				title: "",
				path: "assets/files/projects/cern/ntof/FileDisplay.png"
			},
		],
		files_attached: [
			"assets/files/projects/cern/ntof/FileDisplay.pdf",
		],
	},
	
	/*
		Confluence
			Définir la structure pour toute la documentation technique de la section
			Former les utilisateurs
			Créer du contenu
			Définir Best practices
			Mainteinr + support technique
			5 Confluence spaces
			Seul
			Collaboration BE/CO
	*/
	{
		enable: true,
		title: "Confluence administrator",
		summary: "Administration of wiki-like spaces",
		thumbnail: "assets/images/projects/thumbnails/24-300x100.jpg",
		context: {
			about: [
				"All the section's documentation was stored on a distributed file system and the sharing and security settings were handled using ACL. As the data organisation and safety became difficult to handle, it has been decided to move everything to a wiki platform named Confluence. Another objective was to unify the documentation of the different projects to facilitate co-working.",
				"As I was working alone on this project, I defined the best practices and a data organisation for the wiki workspace. I then conducted workshops with the projects leaders of the section. I also ensure the role of technical support for all the members of the section."
			],
			category: [
				"CERN"
			],
			roles: "All",
			date: "2015-2018",
			technologies: [
				"Confluence",
				"JavaScript",
				"CSS"
			],
		},
		images: [
			{
				title: "",
				path: "assets/files/projects/cern/confluence/screenshot.jpg"
			},
		],
	},
	
	/*
		Représentant sites web section
			Consultant web
			Support maintenance (15 sites drupal)
	*/
	{
		enable: true,
		title: "Person-to-go for projects web related",
		summary: "Consultant and administrator of websites",
		thumbnail: "assets/images/projects/thumbnails/22-300x100.jpg",
		context: {
			about: [
				"While I was working at CERN, I became the person to go for all projects web related at the section level. I then maintained around 15 websites developed using Drupal."
			],
			category: [
				"CERN"
			],
			roles: "Consultant and developer",
			date: "2015-2018",
			technologies: [
				"PHP",
				"Drupal"
			],
		},
	},
	
	/*
		Organigrammes
			Developper site web
			Création interface + database + e-group
			Partager avec secrétaires
			Training users
			Support/Maintenance
			PHP/JS/Bootstrap/Oracle/LDAP
			Autonomie -> proposition autonome pour répondre au besoin d'un collègue
	*/
	{
		enable: true,
		title: "Organograms ",
		summary: "Website used to generate organograms  as SVG",
		thumbnail: "assets/images/projects/thumbnails/42-300x100.jpg",
		context: {
			about: [
				"As the organograms  generator provided by the CERN was not matching the expectations of the higher-ups of the department, I have been asked to develop a new generator.",
				"I developed it using JavaScript to create organograms  as SVG files.",
				"I also created a database and an 'administrator' interface to allow the secretaries to custom the organograms .",
				"In this project, I was working in full autonomy. I also performed training lessons for the users."
			],
			category: [
				"CERN"
			],
			roles: "All roles",
			date: "2016-2018",
			technologies: [
				"PHP",
				"JavaScript",
				"HTML5",
				"BootStrap",
				"Oracle",
				"LDAP",
				"SVG"
			],
		},
		images: [
			{
				title: "",
				path: "assets/files/projects/cern/organograms/example.png",
			},
		],
	},
	
	/*
		Représentant site web dep.
			Participer workshop
			Training drupal
			Dev comm skills
	*/
	{
		enable: true,
		title: "Department consultant",
		summary: "Consultant for the Engineering Department at CERN",
		thumbnail: "assets/images/projects/thumbnails/142-300x100.jpg",
		context: {
			about: [
				"In the context of developing a new website for the department Engineering at CERN and for the CERN itself, I participating to workshops and trainings.",
			],
			category: [
				"CERN"
			],
			company: {
				about: "The Engineering Department provides CERN with the Engineering Competences, Infrastructure Systems and Technical Coordination required for the design, installation, operation, maintenance and dismantling phases of the CERN accelerator complex and its experimental facilities.",
				official_website: {
					link_website: "https://en.web.cern.ch/content/en-department",
					link_text: "Official website"
				}
			},
			roles: "Consultant",
			date: "2017-2018",
			technologies: [
				"Drupal"
			],
		},
	},

	/*
		60 Ans du CERN
			Communication en Français et en Anglais
			Traduction affiche
			Animation stand
	*/
	{
		enable: true,
		title: "60 years of CERN",
		summary: "Animator for the evenement organised for the 60 years anniversary of CERN",
		thumbnail: "assets/images/projects/thumbnails/106-300x100.jpg",
		context: {
			about: [
				"In the context of the 60 years anniversary of CERN, with a coworker, we translated large flyers explaining what our section was doing from english to french.",
				"Then I was in charge of animating a stand all a day during the event open to the public."
			],
			category: [
				"CERN"
			],
			company: {
				about: "CERN, the European Organization for Nuclear Research, is one of the world's largest and most respected centres for scientific research.",
				official_website: {
					link_website: "https://home.cern/",
					link_text: "Official website"
				}
			},
			roles: "Animator and translator",
			date: "2014",
		},
	},
	
	/*
		Welcome new people
			Mettre à l'aise
			Intégration
			répondre aux questions
			Shared knowledge
		
		Best Practices
			C++
			Web dev
			Java
			PLC
	*/
	
	/* ************************************************* */
	/* Engineering school								 */
	/* ************************************************* */
	/*
		Traitement d'image
			Projet scolaire
			Traitement d'images
			Rédaction du cahier des charges
			Groupe de 2
			Parallélisation sur cluster (40 machines)
			C++ MPI - Algorithme de squelettisation
	*/
	{
		enable: true,
		title: "Image processing",
		summary: "Software used to perform image processing",
		thumbnail: "assets/images/projects/thumbnails/111-300x100.jpg",
		context: {
			about: [
				"This objective of this project was to learn what processes was 'hiding' behind image processings. In the second part of this project we had to optimize the processings for a cluster of 40 servers and compare the results with and without the parallelization.",
				"With a co-worker, we develloped a program using C++ to reproduce up to 50 processings. Then we used the library MPI ",
				"From the beginning, I was involved in the research and the writing of the specifications. Moreover I was in charge of developing the modules like rotations and squeletisations.",
				"Our teachers really appreciated the results and the presentation. It led our team to present the project in front of a non-technical public during the open house days."
			],
			category: [
				"Engineering school"
			],
			roles: "Shared leadership",
			date: "2012",
			technologies: [
				"C++",
				"MPI"
			],
		},
		images: [
			{
				title: "Original picture",
				path: "assets/files/projects/eisti/image_processing/original.png",
			},
			{
				title: "Threshold 127",
				path: "assets/files/projects/eisti/image_processing/threshold127.png",
			},
			{
				title: "Skeletisation",
				path: "assets/files/projects/eisti/image_processing/skeletonization_01.png",
			},
			{
				title: "Skeletisation - Algorithm Zhang-Suen",
				path: "assets/files/projects/eisti/image_processing/skeletonization_02.png",
			},
		],
	},
	
	/*
		Réseau social de Toulouse
			Equipe de 4
			Communication avec inventeur du concept
			Web/Java/SQL
			CI/CD -> Agile (Kanban)
			Rédaction du cahier des charges
			Comprendre le besoin
	*/
	{
		enable: true,
		title: "Local social network",
		summary: "Development of a social network for Toulouse",
		thumbnail: "assets/images/projects/thumbnails/61-300x100.jpg",
		context: {
			about: [
				"One of the projects I worked on during my studies at the engineering school was called 'company project'. A team of 4 students was invited to work on a project proposed by a company.",
				"With my colleagues, we had the chance to work on the project 'Solicity'. The objective was to develop a social network for Toulouse city.",
				"The timing was too short to develop the complete system so we offered to the company to go as far as possible and provide a full documentation for the next team that will work on this project after us. They accepted our offer.",
				"We, then, developed a minimum viable product after having written a complete documentation to details the specifications. Following the Agile methodology, we validated as often as possible the development with the client.",
			],
			category: [
				"Engineering school"
			],
			company: {
				about: "Project created by Jonathan Cazaentre and Marie Seurin.",
			},
			roles: "Developer in a shared leadership team",
			date: "2013-2014",
			technologies: [
				"HTML",
				"CSS",
				"JavaScript",
				"Java",
				"Framework Play",
			],
		},
		images: [
			{
				title: "",
				path: "assets/files/projects/eisti/solicity/solicity.png",
			},
		],
	},
	
	/*
		Team DZT
			Rédaction (scénariste, dialoguiste, compositeur)
			Capture son
			Réalisateur
			Acteur
			Beaucoup de créations spontanées
	*/
	{
		enable: true,
		title: "Project 'graduation party'",
		summary: "Member of the animation team for the graduation party at the engineering school",
		thumbnail: "assets/images/projects/thumbnails/25-300x100.jpg",
		context: {
			about: [
				"In order to prepare the graduation party at the engineering school, with a friend of mine, we formed a team in charge of preparing videos for the party.",
				"We decided to integrate all of our comrades as actors and also as much of teachers and administrative workers of the school.",
				"I personally written several scripts and songs and during the filming periods, I was mainly in charge of the staging and the sound capture.",
				"The party has been a huge success. Even, if at the end, we realized so much videos that we didn't had time to go through all of them during the party.",
			],
			category: [
				"Engineering school"
			],
			company: {
				about: "'Poule production'",
			},
			roles: "Stage director, writer and sound engineer",
			date: "2014",
		},
	},
	
	/*
		Stage MyOlympe
			Proposition solution technique
			Très bonne intégration dans l'équipe
			Wordpress/SQL/C/ModBus
			Site web de l'entreprise
			Autonome
	*/
	{
		enable: true,
		title: "Internship at My Olympe",
		summary: "5 months internship based on web development",
		thumbnail: "assets/images/projects/thumbnails/49-300x100.jpg",
		context: {
			about: [
				"I joined MyOlympe for an 5 month internship based on web development.",
				"The main objective was to develop the official website for the company using Wordpress.",
				"I first try to use a free template, but it was not matching the expectations of the higher ups, so I made a second one based on a theme that was purchased by the company.",
				"I also developed two libraries for data visualisation in JavaScript and a data saving module for a system based on ModBus."
			],
			category: [
				"Engineering school"
			],
			company: {
				about: "My Olympe is company is a company that equips homes, offices and buildings with the most suitable smart technology in order to provide comfort, safety, savings and energy control.",
				official_website: {
					link_website: "https://www.my-olympe.com/",
					link_text: "My Olympe"
				}
			},
			roles: "Developer, designer",
			date: "2013",
			technologies: [
				"Wordpress",
				"PHP",
				"JavaScript",
				"C",
				"MySQL",
				"ModBus"
			],
		},
		images: [
			{
				title: "",
				path: "assets/files/projects/eisti/solicity/company.png",
			
			},
			{
				title: "",
				path: "assets/files/projects/eisti/solicity/dial_electricity.png",
			
			},
			{
				title: "",
				path: "assets/files/projects/eisti/solicity/dial_gaz.png",
			
			},
			{
				title: "",
				path: "assets/files/projects/eisti/solicity/dial_water.png",
			
			},
			{
				title: "",
				path: "assets/files/projects/eisti/solicity/screen1.png",
			
			},
			{
				title: "",
				path: "assets/files/projects/eisti/solicity/screen2.png",
			
			},
			{
				title: "",
				path: "assets/files/projects/eisti/solicity/screen3.png",
			
			},
			{
				title: "",
				path: "assets/files/projects/eisti/solicity/screen4.png",
			
			},
			{
				title: "",
				path: "assets/files/projects/eisti/solicity/screen5.png",
			
			},
			{
				title: "",
				path: "assets/files/projects/eisti/solicity/website_screenshot.png",
			}
		],
	},
	
	/*
		Projet Glacier
			C++/MPI/Big Data
			Équipe de 3
			Cluster de machines (40 serveurs)
			Traitement d'images
			Dans le cadre d'une thèse de doctorat
			Algorithme de colorisation
			Réseau de neurone/IA/Hadoop
	*/
	{
		enable: true,
		title: "Image processing for ice/snow recognition on a glacier",
		summary: "Project coupling neural network and image processing",
		thumbnail: "assets/images/projects/thumbnails/140-300x100.jpg",
		context: {
			about: [
				"This project has been conducted in close relation with a Student preparing a thesis. She shared with us pictures of a glacier she had access to thanks to her thesis. The objective was to create a neural network and try to find the best settings to recognise ice/snow on the pictures.",
				"As part of the analysis of the evolution of the snowpack of a glacier, several cameras were placed around the glacier to cover the entire glacier. Each camera is programmed to take 3 photos per day (8h, 12h and 16h), thus generating a considerable and continuous database over time. In order to estimate the variation of the snowpack, it is necessary to carry out a classification allowing to delimit the snow part of the ice part of the glacier. Thanks to the continuity of the cameras taking pictures, we can obtain the evolution of the classification over time, and therefore have the evolution of the snowpack in time and space.",
				"With two other developers we created a neural network using Hadoop and then performed multiple tests to find the best settings.",
			],
			category: [
				"Engineering school"
			],
			company: {
				about: "Thesis of Élisabeth Ranisavljević",
			},
			roles: "Developer",
			date: "2012-2013",
			technologies: [
				"C++",
				"Cluster",
				"Colorization algorithm",
				"Neural network",
				"Hadoop"
			],
		},
		images: [
			{
				title: "Original",
				path: "assets/files/projects/eisti/glacier/cam1/2009090108_cam01.jpg",
			},
			{
				title: "Colorized",
				path: "assets/files/projects/eisti/glacier/cam1/2009090108_cam.jpg",
			},
			{
				title: "Original",
				path: "assets/files/projects/eisti/glacier/cam1/2009091608_cam02.jpg",
			},
			{
				title: "Colorized",
				path: "assets/files/projects/eisti/glacier/cam1/2009091608_cam.jpg",
			},
			{
				title: "Original",
				path: "assets/files/projects/eisti/glacier/cam1/2009091616_cam03.jpg",
			},
			{
				title: "Colorized",
				path: "assets/files/projects/eisti/glacier/cam1/2009091616_cam.jpg",
			},
		],
	},

	/* ************************************************* */
	/* ATS												 */
	/* ************************************************* */
	/*
		Plateforme éducative
			Conception
			Design
			Création
			Partage
			Maintenance
			HTML/PHP/CSS/SQL
			Très apprécié
			Implication des professeurs
			
		Numbers
			425 questions à choix multiples
			8 utilisateurs
			70 visites
			1399 stats
			172 tirages
	*/
	{
		enable: true,
		title: "Educational learning platform",
		summary: "Learning platform shared with co-workers",
		thumbnail: "assets/images/projects/thumbnails/20-300x100.jpg",
		context: {
			about: [
				"While I was in preparatory school, I developed a platform in order to test my knowledge.",
				"The idea was to generate automatically a quiz of 20 multiple choice questions. Once answered and a validation page allows the student to know what was wrong and what was correct. I also wanted the platform to include statistics.",
				"After having developed a basic connection system, reusing what I already developed in my previous projects, I shared the platform with my comrades. I created a database of 425 multiple choices. I also asked the teachers if they wanted to help me by adding questions on the platform, but unfortunately they were too busy to do it.",
				"5 of my colleagues really appreciated the platform and used it a lot to train before the day of the final exam. The website reached 170 visits. My teachers also congratulated my efforts."
			],
			category: [
				"Preparatory school"
			],
			roles: "All the roles",
			date: "2010/2011",
			technologies: [
				"HTML",
				"CSS",
				"PHP",
				"SQL"
			],
		},
		images: [
			{
				title: "Screenshot",
				path: "assets/files/projects/ats/platforme-educative/screenshot.png",
			},
		],
	},


	/* ************************************************* */
	/* BTS												 */
	/* ************************************************* */
	/*
		Projet note de frais
			Chef de projet
			MySQL
			PHP backend
			Livraison
	*/
	{
		enable: true,
		title: "Generator of statements of travel expenses",
		summary: "My first IT project made more than 10 years ago",
		thumbnail: "assets/images/projects/thumbnails/88-300x100.jpg",
		context: {
			about: [
				"During my studies, the teachers of the high school I was studying in, were looking for developers to create a basic system to generate PDF for their statements of travel expenses.",
				"With two of my comrades we developed a website connected to a database we designed.",
				"I was personaly in charge of developing the backend using PHP and in order to generate PDF I used the library 'fpdf.php' (v1.53).",
				"The project has been presented in front of three teachers and we successfully delivered the project as a ZIP file. The teachers said that the result was what they were expecting. The team was really happy to hear it, especially because it was our first IT project."
			],
			category: [
				"BTS"
			],
			roles: "Project leader and developer",
			date: "2009",
			technologies: [
				"HTML",
				"CSS",
				"PHP",
				"fpdf.php",
				"MySQL",
			],
		},
		images: [
			{
				title: "Form",
				path: "assets/files/projects/bts/GeneratorForStatementOfTravelExpenses/EFD1.jpg",
			},
			{
				title: "EFD1",
				path: "assets/files/projects/bts/GeneratorForStatementOfTravelExpenses/EFD2.jpg",
			},
			{
				title: "EFD2",
				path: "assets/files/projects/bts/GeneratorForStatementOfTravelExpenses/Form.png",
			},
		],
	},

	/*
		Projet Porte personnage
			Documentaliste
			Rédaction specs
			C++
			Module Zigbee de communication
			Livraison partielle
	*/
	{
		enable: true,
		title: "Turning target system control",
		summary: "Software used to control turning targets",
		thumbnail: "assets/images/projects/thumbnails/91-300x100.jpg",
		context: {
			about: [
				"Project developed within the high school context for the company 'Automatismes Production'. The objective was to develop a control system for the turning targets including stimuli like a pressure plates or camera detection. The interface should also allow to save and load scenarios. All the communications with the turning targets should be achieve using ZigBee modules.",
				"Within a team of 4 people, we developed this project using C++.",
				"I was in charge of developing the library for the ZigBee communication. All the char buffers were handled without any external libraries.",
				"We presented the projects in front of the client and a jury of teachers. Their feedback was mainly good, except for the facts that the scenario part was not working and the stimuli control system was not implemented within the interface."
			],
			category: [
				"BTS"
			],
			company: {
				about: "The company 'Automatismes Production' is specialized in the development and manufacture of modular radio-controlled automatic turning target systems. These equipment are intended for shooting training. The company's customers are diverse like army and police training centers and clubs of semi-professionals and amateurs.",
				official_website: {
					link_website: "https://www.societe.com/societe/automatismes-production-339771917.html",
					link_text: "Company closed since 2014."
				}
			},
			roles: "Developper and librarian of the project",
			date: "2010 (25 weeks)",
			technologies: [
				"C++",
				"ZigBee"
			],
		},
		images: [
			{
				title: "Turning target system",
				path: "assets/files/projects/bts/TurningTargetSystemControl/TurningTargetSystem.png",
			},
		],
	},
		
	/*
		Stage Canada
			Apprentissage Flash
			Expérience à l'étranger
			Equipe design/multimédia
	*/
	{
		enable: true,
		title: "Internship in Canada",
		summary: "6 weeks internship in Canada into the multimedia team",
		thumbnail: "assets/images/projects/thumbnails/11-300x100.jpg",
		context: {
			about: [
				"During my studies, I went to Canada for a 6 weeks internship within the multimedia team of the Collège Communautaire du Nouveau Brunswick (Campus of Bathurst).",
				"During this internship, my main goal was to discover what it means to work within a multimedia team.",
				"So I spent time shadowing every member of the team. Discovering how they create flyers, school books, websites and many others things. I also tried to reproduce a part of their work using mainly Flash and 3D technologies.",
				"After returning to France I presented the result of my work and what I learned from this wonderful expertise to my teachers. I learned a lot of things from this internship, especially because it was my first time working closely with professionals of the IT world."
			],
			category: [
				"BTS"
			],
			company: {
				about: "Collège Communautaire du Nouveau Brunswick (Campus de Bathurst)",
				official_website: {
					link_website: "https://ccnb.ca/accueil/.aspx",
					link_text: "Site officiel"
				}
			},
			roles: "",
			date: "From May 18 to June 26 (2009)",
			technologies: [
				"Flash",
				"Macromedia Flash Player",
				"Adobe CS4",
				"AutoDesk 3D Studio Max"
			],
		},
		images: [
			{
				title: "Turning target system",
				path: "assets/files/projects/bts/InternshipInCanada/campus_bathurst.jpg",
			},
		],
	},
		
	/*
		Mon site perso
			HTML/PHP/SQL/CSS
	*/
	{
		enable: true,
		title: "Personal website",
		summary: "My first personal website, mainly used to discover basis of web development",
		thumbnail: "assets/images/projects/thumbnails/96-300x100.jpg",
		context: {
			about: [
				"I really enjoyed working with web technologies, so I wanted to set up a 'test' platform, where I could experienced some coding of my own.",
				"I ordered a free FTP and performed my firsts deployments."
			],
			category: [
				"BTS"
			],
			roles: "All roles",
			date: "2009",
			technologies: [
				"HTML",
				"CSS",
				"PHP",
				"MySQL"
			],
		},
	},

]