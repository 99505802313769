<template>
	<section class="footer">
		<a class="button-to-top" :class="{show: !isTop}" href="#home">Back to top</a>
		<div class="footer-bar">
			<div>Created by Alexandre Giraud</div>
			<div>2020 - All Rights Reserved</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'Footer',
	data () {
		return {
			isTop: true
		}
	},
	created () {
		window.addEventListener('scroll', this.handleScroll);
	},
	destroyed () {
		window.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		handleScroll () {
			this.isTop = (window.scrollY == 0)
		},
		toTop() {
			window.scrollY = 0
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
section {
	width: 100%;
    margin: 0;
}

.footer-bar {
	width: 100%;
    margin: 0;
	background-color: #adadad;
	display: flex;
	justify-content: space-around;
	align-items: center;
	min-height: 10vh;
}

.button-to-top {
	width: 40px;
    height: 40px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    text-indent: -99999px;
    cursor: pointer;
    background: url(../assets/images/btt-black.png) no-repeat 0 0;
    display: none;
}

.show {
	display: block;
}
</style>