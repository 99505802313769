<template>
	<div class="projectCard">
		<!--<img :src="project.image_src">-->
		<div class="overlay">
			<div class="title">{{ project.title }}</div>
			<a :href="project.link_source">See code source</a>
			<a :href="project.link_demo">See a demo</a>
			<a :href="project.link_more">See more ...</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'projectCard',
	props: {
		project: Object,
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.projectCard {
	position: relative;
	width: 300px;
	background-color: #adadad;
    box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.5);
}

.projectCard img {
	margin-top: -0.2px;
	margin-bottom: -3.8px;
}

/*
.overlay {
	opacity: 0;
	position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(70, 70, 67, .8);
    color: white;
    font-size: 1.4em;
}
*/

.overlay:hover {
	opacity: 1;
}

.title {
    font-size: 1.4em;
}

a {
	background-color: #fff;
	color: #000;
	cursor: pointer;
	margin: 1px 15px;
}

a:hover {
	background-color: #000;
	color: #fff;
}
</style>
