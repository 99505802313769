<template>
	<section class="contact-me">
		<a id="contact-me"></a>
		<div class="contact-me-content">
			<div class="header">
				<h1>Contact Me</h1>
				<p>If you're interested in chatting or have any question just reach me out! :)</p>
			</div>
			<div class="social-networks">
				<ul class="social-networks-list">
					<li class="social-networks-item">
						<a href="https://www.linkedin.com/in/giraud-alexandre/">
							<img src="../assets/images/logos/LINKEDIN.png"  />
						</a>
					</li>
					<li class="social-networks-item">
						<a href="https://github.com/alexandregiraud64">
							<img src="../assets/images/logos/GITHUB.png"  />
						</a>
					</li>
					<li class="social-networks-item">
						<a href="#" class="crypted-mail"
							data-name="alexandregiraud64"
							data-domain="gmail"
							data-tld="com"
							onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;">
							<img src="../assets/images/logos/MAIL.png"  />
						</a>
					</li>
				</ul>
			</div>
				
		</div>
	</section>
</template>

<script>
export default {
	name: 'ContactMe',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contact-me {
	min-height: 40vh;
}

.header p {
	margin: 0 auto;
}

.contact-me-content {
	width: 70%;
	margin: 0 auto 35px auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.social-networks {
	margin: 45px 0;
}

.social-networks-content {
	width: 70%;
	justify-content: center;
	margin: 0 auto 15px auto;
	display: flex;
	border-bottom: 1px solid #000;
}

.experience-with {
	display: flex;
	justify-content: center;
	width: 100%;
	flex-direction: column;
	padding-bottom: 35px;
}

.social-networks-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	width: 100%;
	padding: 0;
	margin: 0;
}

.social-networks-list li {
	margin: 0 40px;
}

.social-networks-item {
	list-style : none;
}

.social-networks-item img {
    max-width: 150px;
    max-height: 150px;
    margin-top: 25px;
}

.social-networks-item-description::before {
	content: " - ";
}
</style>
