<template>
	<div id="app">
		<a id="home"></a>
		<Home />
		<Navigation />
		<About />
		<Technologies />
		<PortFolio />
		<ContactMe />
		<Footer />
	</div>
</template>

<script>
import About from './components/About.vue'
import ContactMe from './components/ContactMe.vue'
import Footer from './components/Footer.vue'
import Home from './components/Home.vue'
import Navigation from './components/Navigation.vue'
import PortFolio from './components/PortFolio.vue'
import Technologies from './components/Technologies.vue'

export default {
	name: 'App',
	components: {
		About,
		ContactMe,
		Footer,
		Home,
		Navigation,
		PortFolio,
		Technologies
	}
}
</script>

<style>
/* ############################ */
/* Global 						*/
/* ############################ */
* {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: Arial;
	background-color: #e8e8e8;
}

html {
  scroll-behavior: smooth;
}

/* ############################ */
/* Header 						*/
/* ############################ */
.header {
	display: flex;
	flex-direction: column;
	font-size: 1.8em;
}

.header h1 {
	display: flex;
	justify-content: center;
	font-size: 1em;
}

.header {
	text-align: center;
}

/* ############################ */
/* Section 						*/
/* ############################ */
section {
	width: 100%;
	/*border-bottom: 1px solid #000;*/
    /*margin: 35px auto;*/
	/*width: 70%;*/
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/* ############################ */
/* Tooltip 						*/
/* ############################ */
.tooltip {
	position: relative;
	display: inline-block;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;

	/* Position the tooltip */
	position: absolute;
	z-index: 1;
	bottom:0;
	left:0;
	right:0;
	width: 100%;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
}

/* ############################ */
/* Tooltip 						*/
/* ############################ */
.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}

.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}
.btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}

.btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
}

.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
}

.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}

.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
}

.btn-link {
    font-weight: 400;
    color: #007bff;
    background-color: transparent;
}

.btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
}

.btn, .btn:focus, .btn:hover {
    text-decoration: none;
}

</style>